import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  FormControl,
  Collapse,
  MenuItem,
  Select,
  InputLabel,
  Grid,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import "./home.css";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../context/userLoaderContext";
import HomePageBanner from "../../assets/FarmersLife_Homepage_banner_field.png";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FinancialRating from "../../assets/financial_rating.png";
import Ellipse from "../../assets/Ellipse.png";
import { Footer } from "../../components/footer/footer";
import { GetResourceConfigDataAPI, getResourceFileAPI } from "../../api/resourceConfig";
import EastIcon from '@mui/icons-material/East';
import HistoryIcon from '@mui/icons-material/History';

const Home = () => {
  require("dotenv").config();
  const { state, SetFlagLoader } = useContext(Context);
  const [open, setOpen] = useState(false);
  const [resourceList, setResourceList] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const navigate = useNavigate();


  const CollapseNewMenu = (pdfGroupCategoryId) => {
    let old = [...resourceList]
    old.map((resource) => {
      if (resource?.pdf_category_resource_data?.length) {
        resource?.pdf_category_resource_data?.map((group) => {
          if (group?.pdf_group_category_id == pdfGroupCategoryId) {
            group.colllaps = !group.colllaps
          }
        })
      }
    })
    setResourceList(old)
  };
  useEffect(() => {
    SetFlagLoader(true);

    GetResourceConfigDataAPI((res) => {
      console.log("resss", res)
      res.data.data.map((category) => {
        if (category?.pdf_category_resource_data?.length) {
          category?.pdf_category_resource_data?.map((group) => {
            if (group.pdf_resource_flag == false) {
              group.colllaps = false
            }
          })
        }
      })
      setResourceList(res.data.data)
      SetFlagLoader(false);
    }, (err) => {
      SetFlagLoader(false);
      console.log("errr", err)
    })
  }, []);

  const goFileItems = [
    {
      value: 1,
      label: "General"
    },
    {
      value: 2,
      label: "POS"
    },
    {
      value: 1,
      label: "Contracting"
    }
  ]
  function documentRedirection(googleStorageId) {
    SetFlagLoader(true)
    // before: navigate(`/view-resource?google_storage_id=${group.google_storage_id}`) 
    getResourceFileAPI(googleStorageId, (res) => {
      if (res?.data?.status) {
        setTimeout(() => {
          window.open(res?.data?.data[0]?.destination, "_blank")
        })
      }
      console.log("ressss", res)
      SetFlagLoader(false)
    }, (err) => {
      SetFlagLoader(false)
      console.log("ressss", err)
    })
  }
  return (
    <>
      <Box>
        <Box>
          <Box className="home_page_banner_root">
            <Box>
              <img src={HomePageBanner} alt="banner_image" />
            </Box>
            <Box className="home_page_banner_text">
              <Typography variant="span">
                Welcome, {state?.user?.first_name} {state?.user?.last_name}!
              </Typography>
            </Box>
          </Box>
          <Box className="home_page_main_content">
            <Box className="description_about_company">
              <Typography textAlign="center">
                Through the efforts of independent agents and advisors
                nationwide, the future of American retirement is increasingly
                bright. To help you streamline business and provide your clients
                with exceptional service throughout the life of your
                relationships, we encourage you to utilize the resources
                provided below.
              </Typography>
            </Box>

            <Grid container justifyContent="center" mt={5}>
              <Grid item xs={10} sm={8} md={5} lg={4.7} xl={4.3} sx={{ marginRight: { sm: "0px", md: "30px" }, marginBottom: { xs: "20px", md: "0px" } }} className="start-e-app-btn-myga">
                <Grid container sx={{ padding: "25px 0px 25px 0px" }}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="start_text_item">
                    <Typography className="start_text_myga">
                      MYGA Application
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="form_type_item">
                    <Grid container sx={{ padding: "0px 15px" }} gap={"10px"} direction={{ xs: "column", md: "row" }} justifyContent={{ sm: "center" }}>
                      <Grid item sm={5} md={5} lg={3.5} xl={3.5} sx={{ textAlign: "center" }} mb={{ xs: "5px", md: 0 }}>
                        <Button
                          variant="contained"
                          disableElevation
                          endIcon={<EastIcon />}
                          fullwidth
                          onClick={() => {
                            navigate("/myga_application")
                          }}
                          className="myga_start_btn"
                        >
                          Start E-App
                        </Button>
                      </Grid>
                      <Grid item sm={5} md={5} lg={3.5} xl={3.5} sx={{ textAlign: "center" }} mb={{ xs: "5px", md: 0 }}>
                        <Button
                          variant="contained"
                          disableElevation
                          endIcon={<HistoryIcon />}
                          fullwidth
                          onClick={() => {
                            navigate("/myga_application_history")
                          }}
                          className="myga_start_btn"
                        >
                          View App
                        </Button>
                      </Grid>
                      <Grid item sm={5} md={5} lg={3.5} xl={3.5} sx={{ textAlign: "center" }}>
                        <Button
                          variant="contained"
                          disableElevation
                          startIcon={<CalculateOutlinedIcon />}
                          onClick={() => {
                            navigate("/Product-Illustration")
                          }}
                          fullwidth
                          className="myga_illu_btn"
                        >
                          MYGA Illustration
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={10} sm={8} md={5} lg={4.7} xl={4.3} className="start-e-app-btn-fia">
                <Grid container sx={{ padding: "25px 0px 25px 0px" }}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="start_text_item">
                    <Typography className="start_text_fia">
                      Fixed Index Annuity Application (FIA)
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="form_type_item">
                    <Grid container sx={{ padding: "0px 15px" }} gap={"10px"} direction={{ xs: "column", md: "row" }} justifyContent={{ sm: "center" }}>
                      <Grid item sm={5} md={5} lg={3.6} xl={3.5} sx={{ textAlign: "center" }} mb={{ xs: "5px", md: 0 }}>
                        <Button
                          variant="contained"
                          disableElevation
                          endIcon={<EastIcon />}
                          // fullwidth
                          onClick={() => {
                            navigate("/fia_application")
                          }}
                          className="fia_start_btn"
                        >
                          Start E-App
                        </Button>
                      </Grid>
                      <Grid item sm={5} md={5} lg={3.6} xl={3.5} sx={{ textAlign: "center" }} mb={{ xs: "5px", md: 0 }}>
                        <Button
                          variant="contained"
                          disableElevation
                          endIcon={<HistoryIcon />}
                          // fullwidth
                          onClick={() => { navigate("/fia_application_history") }}
                          className="fia_start_btn"
                        >
                          View App
                        </Button>
                      </Grid>
                      <Grid item sm={5} md={5} lg={3.6} xl={3.5} sx={{ textAlign: "center" }}>
                        <Button
                          variant="contained"
                          disableElevation
                          startIcon={<CalculateOutlinedIcon />}
                          // fullWidth
                          onClick={() => {
                            window.open("https://imagisoft.com/farmers/fia", '_blank')
                          }}
                          className="fia_illu_btn"
                        >
                          FIA Illustration
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className="bottom_section" justifyContent="center">
              <Grid item xs={11}>
                <Grid container justifyContent="center">
                  <Grid item xs={12} mt={4}>
                    <Grid container justifyContent={{ xs: "space-between", md: "center" }}>
                      <Grid item md={6}>
                        <Grid container direction={{ xs: "column", md: "row" }} justifyContent={{ sm: "end", md: "start" }}>
                          <Grid item xl={3}>
                            {state?.userType !== "Agent" &&
                              <Button
                                variant="contained"
                                disableElevation
                                startIcon={<SettingsOutlinedIcon />}
                                // fullWidth
                                onClick={() => {
                                  navigate("/pdf-config")
                                }}
                                className="resource_btns config_btn"
                              >
                                Resource Config
                              </Button>
                            }
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={6}>
                        <Grid container direction={{ xs: "column", md: "row" }} justifyContent={{ sm: "end", md: "end" }}>
                          <Grid item xl={2.5} textAlign="end" className="illustration_btn_item" mb={{ xs: "5px", md: 0 }}>
                            <Button
                              variant="contained"
                              disableElevation
                              startIcon={<FileUploadOutlinedIcon />}
                              // fullWidth
                              onClick={() => {
                                navigate("/upload-tool")
                              }}
                              className="resource_btns upload_btn"
                            >
                              Upload Tool
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="group_of_box_root">
                      {resourceList.map((category, i) => {
                        return (
                          <>
                            <Box>
                              <List
                                className="application_box_root"
                                sx={{
                                  width: "100%",
                                  maxWidth: 450,
                                  bgcolor: "background.paper",
                                  height: "380px",
                                  display: "flex",
                                  alignItems: "flex-start",
                                  flexDirection: "column",
                                }}
                                component="nav"
                                subheader={
                                  <ListSubheader
                                    className={category == resourceList?.length ? "application_box_header" : "application_box_header box_margin_right"}
                                    component="div"
                                    id="nested-list-subheader"
                                    style={{ width: "100%" }}
                                  >
                                    {category.pdf_category_name}
                                  </ListSubheader>
                                }
                              >
                                {category.pdf_category_resource_data?.length ?
                                  category.pdf_category_resource_data.map((group, j) => {
                                    return (
                                      group?.pdf_resource_flag
                                        ?
                                        <ListItemButton className="list_item" style={{ paddingTop: "8px" }}>
                                          <ListItemIcon sx={{ minWidth: "20px" }}>
                                            <img src={Ellipse} alt="" />
                                          </ListItemIcon>
                                          {group?.resource_type_name === "File upload" ? (
                                            <>
                                              <a>
                                                <ListItemText primary={group?.resource_name} onClick={() => {
                                                  documentRedirection(group.google_storage_id)
                                                }} />
                                              </a>
                                            </>
                                          ) : (
                                            <>
                                              {group?.resource_type_name === "URL" ? (
                                                <>
                                                  <ListItemText
                                                    sx={{ textDecoration: "underline", cursor: "pointer", }}
                                                    primary={group?.resource_name}
                                                    onClick={() => {
                                                      if (group?.resource_link?.indexOf("http") == -1) {
                                                        group.resource_link = "https://" + group?.resource_link
                                                      }
                                                      window.open(group?.resource_link)
                                                    }}
                                                  />
                                                </>
                                              ) : (
                                                <>
                                                  <ListItemText primary={group?.resource_name}
                                                  />
                                                </>
                                              )}
                                            </>
                                          )}
                                        </ListItemButton>
                                        :
                                        <>
                                          <ListItemButton className="listItemButton" onClick={() => { CollapseNewMenu(group.pdf_group_category_id) }}>
                                            <ListItemIcon
                                              className="listItemIcon"
                                              sx={{
                                                minWidth: "20px",
                                                paddingInlineStart: "10px",
                                                color: "white",
                                              }}
                                            >
                                              {group.colllaps ? (
                                                <ExpandLess />
                                              ) : (
                                                <ExpandMore />
                                              )}
                                            </ListItemIcon>
                                            <ListItemText primary={group?.pdf_group_category_name} />
                                          </ListItemButton>
                                          {(group.pdf_group_resource_data?.length && group.colllaps) ?
                                            group.pdf_group_resource_data.map((resource, k) => {
                                              return (
                                                <>
                                                  <Collapse
                                                    in={group.colllaps}
                                                    timeout="auto"
                                                    unmountOnExit
                                                  >
                                                    <List component="div" disablePadding>
                                                      <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon sx={{ minWidth: "20px" }}>
                                                          <img src={Ellipse} alt="" />
                                                        </ListItemIcon>
                                                        {resource?.resource_type_name === "File upload" ? (
                                                          <>
                                                            <a>
                                                              <ListItemText primary={resource.resource_name} onClick={() => {
                                                                documentRedirection(resource.google_storage_id)
                                                              }} />
                                                            </a>
                                                          </>
                                                        ) : (
                                                          <>
                                                            {resource?.resource_type_name === "URL" ? (
                                                              <>
                                                                <ListItemText
                                                                  sx={{ textDecoration: "underline", cursor: "pointer", }}
                                                                  primary={resource.resource_name}
                                                                  onClick={() => {
                                                                    if (resource.resource_link.indexOf("http") == -1) {
                                                                      resource.resource_link = "https://" + resource.resource_link;
                                                                    }
                                                                    window.open(resource.resource_link);
                                                                  }}
                                                                />
                                                              </>
                                                            ) : (
                                                              <>
                                                                <ListItemText primary={resource.resource_name}
                                                                />
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </ListItemButton>
                                                    </List>
                                                  </Collapse>
                                                </>
                                              )
                                            })
                                            : ""
                                          }
                                        </>
                                    );
                                  })
                                  :
                                  ""
                                }
                              </List>
                            </Box>
                          </>
                        );
                      })}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Box>{" "}
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default Home;
