import axiosInstance from "./axios";

const GetUserDetailAPI = async (data, onSuccess, onError) => {
    try {
        console.log(data);
        const res = await axiosInstance.get("/api/user", {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(res);
    }
    catch (err) {
        onError && onError(err);
    }
};
const GetUserStatusAPI = async (data, onSuccess, onError) => {
    try {
        console.log(data);
        const res = await axiosInstance.get("api/user/status", {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(res);
    }
    catch (err) {
        onError && onError(err);
    }
};

const UpdateUserStatusAPI = async (data, onSuccess, onError) => {
    try {
        console.log(data);
        // debugger;
        const res = await axiosInstance.patch("api/user/update", { status_id: data.status_id, user_id: data.user_id }, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(res);
    }
    catch (err) {
        onError && onError(err);
    }
};

const UpdateAgentAdminDetailAPI = async (data, onSuccess, onError) => {
    try {
        console.log(data);
        // debugger;
        const res = await axiosInstance.post("api/user/update", data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(res);
    }
    catch (err) {
        onError && onError(err);
    }
};

export { GetUserDetailAPI, UpdateUserStatusAPI, GetUserStatusAPI, UpdateAgentAdminDetailAPI };