import React, { Fragment, useState, useContext, useEffect, memo, useRef } from "react";
import {
    Box,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    Select,
    TableHead,
    TableRow,
    Typography,
    Button,
    InputLabel,
    FormControl,
    NativeSelect,
    TextField,
    Dialog,
    DialogTitle,
    Grid,
    ClickAwayListener,
    Snackbar,
    Alert,
} from "@mui/material";
import "./agents.css";
import { Context } from "../../context/userLoaderContext";
import {
    GetUserDetailAPI,
    UpdateUserStatusAPI,
    GetUserStatusAPI,
    UpdateAgentAdminDetailAPI,
} from "../../api/getUserDetailAPI";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/loader";
import Tooltip from '@mui/material/Tooltip';
import MuiPhoneNumber from "material-ui-phone-number";
import GlobalPopUp from "./globalpopup";


const AgentProfile = () => {
    const [openStatusMenu, setOpenStatusMenu] = useState(false);
    const [tempSnackbarOpen, setTempSnackbarOpen] = useState({
        flagOpen: false,
        message: "",
        severity: "success"
    });

    const navigate = useNavigate();
    // const { SetFlagLoader } = useContext(Context);
    const [flagLoader, SetFlagLoader] = useState(false);
    const { state, SetUser } = useContext(Context);
    const [agentDetails, setAgentDetails] = useState({
        user_id: "",
        user_first_name: "",
        user_email: "",
        user_last_name: "",
        user_website: "",
        user_national_producer_number: "",
        user_phone: ""
    })
    const [agents, setAgents] = useState([]);
    console.log("state", state)
    useEffect(() => {
        if (state.user) {
            setAgentDetails((prevState) => ({
                ...prevState,
                user_id: state.user.user_id,
                user_first_name: state.user.first_name,
                user_last_name: state.user.last_name,
                user_website: state.user.website,
                user_national_producer_number: state.user.npn,
                user_phone: state.user.phone,
                user_email: state.user.email
            }))
        }
    }, [state])

    function CommonContextUpdate(keyname, value) {
        setAgentDetails((prevState) => ({
            ...prevState,
            [keyname]: value
        }))
    }

    async function handleUpdateAdmin() {
        SetFlagLoader(true)
        await UpdateAgentAdminDetailAPI(agentDetails, (res) => {
            setTempSnackbarOpen((prevState) => ({ ...prevState, flagOpen: true, message: res.data.message.message, severity: "success" }))
            let prevData = { ...state.user }
            prevData.first_name = agentDetails.user_first_name;
            prevData.last_name = agentDetails.user_last_name;
            prevData.website = agentDetails.user_website;
            prevData.npn = agentDetails.user_national_producer_number;
            prevData.phone = agentDetails.user_phone

            SetUser(prevData)
            console.log("res", res)
        }, (err) => {
            console.log("error", err)
            setTempSnackbarOpen((prevState) => ({ ...prevState, flagOpen: true, message: "Something went wrong! Please try again later", severity: "error" }))
        })
        SetFlagLoader(false)
    }

    return (
        <Fragment>
            <Snackbar
                open={tempSnackbarOpen.flagOpen}
                autoHideDuration={3000}
                onClose={() => {
                    setTempSnackbarOpen((prevState) => ({ ...prevState, flagOpen: false, message: "" }));
                }}
            >
                <Alert
                    onClose={() => {
                        setTempSnackbarOpen((prevState) => ({ ...prevState, flagOpen: false, message: "" }));
                    }}
                    severity={tempSnackbarOpen.severity}
                    sx={{ width: "100%" }}
                >
                    {tempSnackbarOpen.message}
                </Alert>
            </Snackbar>
            {flagLoader === true && <Loader />}


            <Grid container>


                <Grid item xs={12} className="pr-main-item-wrapper">
                    <Grid container>
                        <Box sx={{ width: "100%" }}>
                            <Grid item xs={6} className="pr-back-btn-item" >
                                <Button
                                    variant="contained"
                                    size="large"
                                    className="pr-back-btn"
                                    onClick={() => {
                                        navigate("/");
                                    }}
                                >
                                    Back
                                </Button>
                            </Grid>
                        </Box>
                        <Grid item xs={6} className="pr-header-sub-item">
                            <Grid container>
                                <Grid item xs={12} className="pr-header-item">
                                    <Typography variant="span">Profile</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box sx={{ padding: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Box className="annuitant_input_fields" sx={{ maxWidth: "750px" }}>
                                            {/* <Box className="annuitant_fullname_and_ssn_row">
                                                <Box className="left_section">
                                                    <TextField
                                                        className={"input_field_width"}
                                                        id="standard-basic"
                                                        label="First Name"
                                                        type="text"
                                                        variant="standard"
                                                        onChange={(e) => {
                                                            CommonContextUpdate("user_first_name", e.target.value);
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: agentDetails.user_first_name?.length > 0 ? true : false,
                                                        }}
                                                        value={agentDetails.user_first_name}
                                                    // disabled={disableAllInputs === true ? true : false}
                                                    />
                                                </Box>

                                                <Box className="right_section">
                                                    <TextField
                                                        // className={annuitantReq ? "input_field_width" : "input_field_width not_required"}
                                                        className={"input_field_width"}
                                                        label="Last Name"
                                                        // disabled={disableAllInputs === true ? true : false}
                                                        type="text"
                                                        variant="standard"
                                                        onChange={(e) => {
                                                            CommonContextUpdate("user_last_name", e.target.value);
                                                        }}
                                                        value={agentDetails.user_last_name}
                                                    />

                                                </Box>
                                            </Box>

                                            <Box className="annuitant_city_and_state_row">
                                                <Box className="left_section">
                                                    <TextField
                                                        className={"input_field_width"}
                                                        label="Email"
                                                        disabled={true}
                                                        type="text"
                                                        variant="standard"
                                                        value={agentDetails.user_email}
                                                    />
                                                </Box>
                                            </Box>

                                            <Box className="annuitant_phone_and_email_row">
                                                <Box className="left_section">
                                                    <MuiPhoneNumber
                                                        name="phone"
                                                        label="Phone"
                                                        sx={{
                                                            '& .MuiPhoneNumber-flagButton': {
                                                                pointerEvents: "none"
                                                            }
                                                        }}
                                                        data-cy="user-phone"
                                                        defaultCountry={"us"}
                                                        onlyCountries={["us"]}
                                                        autoFormat={true}
                                                        countryCodeEditable={false}
                                                        value={agentDetails.user_phone}
                                                        className={"input_field_width"}
                                                        onChange={(value) => {
                                                            console.log("phoneWithFlag", value)
                                                            // let temp = { ...annuitantValidationError }
                                                            // temp.flagAnnuitantPhoneValid = !validatePhoneNumberLatest(value)
                                                            // setAnnuitantValidationError(temp)
                                                            CommonContextUpdate("user_phone", value)
                                                        }}
                                                    // error={annuitantValidationError.flagAnnuitantPhoneValid ? true : false}
                                                    // helperText={annuitantValidationError.flagAnnuitantPhoneValid ? "Invaild Phone Number" : ""}
                                                    // disabled={disableAllInputs === true ? true : false}
                                                    // inputProps={{ readOnly: disableAllInputs === true ? true : false }}
                                                    />

                                                </Box>
                                            </Box>

                                            <Box className="annuitant_city_and_state_row">
                                                <Box className="left_section">
                                                    <TextField
                                                        className={"input_field_width"}
                                                        label="Website"
                                                        // disabled={disableAllInputs === true ? true : false}
                                                        type="text"
                                                        variant="standard"
                                                        onChange={(e) => {
                                                            CommonContextUpdate("user_website", e.target.value);
                                                        }}
                                                        value={agentDetails.user_website}
                                                    />
                                                </Box>

                                            </Box>

                                            <Box className="annuitant_city_and_state_row">
                                                <Box className="left_section">
                                                    <TextField
                                                        className={"input_field_width"}
                                                        label="NPN"
                                                        // disabled={disableAllInputs === true ? true : false}
                                                        type="text"
                                                        variant="standard"
                                                        onChange={(e) => {
                                                            CommonContextUpdate("user_national_producer_number", e.target.value);
                                                        }}
                                                        value={agentDetails.user_national_producer_number}
                                                    />
                                                </Box>

                                            </Box>

                                            <Box>
                                                <Button
                                                    onClick={handleUpdateAdmin}
                                                    sx={{
                                                        backgroundColor: "rgb(217, 162, 46) !important",
                                                        color: "white !important",
                                                        textTransform: "capitalize !important",
                                                        margin: "20px 0px !important",
                                                    }}
                                                >Save</Button>
                                            </Box> */}

                                            <Grid item xs={12}>
                                                <Grid container sx={{ justifyContent: "space-between", gap: "10px" }}>

                                                    <Grid item xs={5.5} className="pr-form-fields-item">
                                                        <TextField
                                                            className={"input_field_width"}
                                                            id="standard-basic"
                                                            label="First Name"
                                                            type="text"
                                                            variant="standard"
                                                            onChange={(e) => {
                                                                CommonContextUpdate("user_first_name", e.target.value);
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: agentDetails.user_first_name?.length > 0 ? true : false,
                                                            }}
                                                            value={agentDetails.user_first_name}
                                                        // disabled={disableAllInputs === true ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={5.5} className="pr-form-fields-item">
                                                        <TextField
                                                            // className={annuitantReq ? "input_field_width" : "input_field_width not_required"}
                                                            className={"input_field_width"}
                                                            label="Last Name"
                                                            // disabled={disableAllInputs === true ? true : false}
                                                            type="text"
                                                            variant="standard"
                                                            onChange={(e) => {
                                                                CommonContextUpdate("user_last_name", e.target.value);
                                                            }}
                                                            value={agentDetails.user_last_name}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={5.5} className="pr-form-fields-item">
                                                        <TextField
                                                            className={"input_field_width"}
                                                            label="Email"
                                                            fullWidth
                                                            disabled={true}
                                                            type="text"
                                                            variant="standard"
                                                            value={agentDetails.user_email}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={5.5} className="pr-form-fields-item">
                                                        <MuiPhoneNumber
                                                            name="phone"
                                                            label="Phone"
                                                            sx={{
                                                                '& .MuiPhoneNumber-flagButton': {
                                                                    pointerEvents: "none"
                                                                }
                                                            }}
                                                            data-cy="user-phone"
                                                            defaultCountry={"us"}
                                                            onlyCountries={["us"]}
                                                            autoFormat={true}
                                                            countryCodeEditable={false}
                                                            value={agentDetails.user_phone}
                                                            className={"input_field_width"}
                                                            onChange={(value) => {
                                                                console.log("phoneWithFlag", value)
                                                                // let temp = { ...annuitantValidationError }
                                                                // temp.flagAnnuitantPhoneValid = !validatePhoneNumberLatest(value)
                                                                // setAnnuitantValidationError(temp)
                                                                CommonContextUpdate("user_phone", value)
                                                            }}
                                                        // error={annuitantValidationError.flagAnnuitantPhoneValid ? true : false}
                                                        // helperText={annuitantValidationError.flagAnnuitantPhoneValid ? "Invaild Phone Number" : ""}
                                                        // disabled={disableAllInputs === true ? true : false}
                                                        // inputProps={{ readOnly: disableAllInputs === true ? true : false }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={5.5} className="pr-form-fields-item">
                                                        <TextField
                                                            className={"input_field_width"}
                                                            label="Website"
                                                            // disabled={disableAllInputs === true ? true : false}
                                                            type="text"
                                                            variant="standard"
                                                            onChange={(e) => {
                                                                CommonContextUpdate("user_website", e.target.value);
                                                            }}
                                                            value={agentDetails.user_website}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={5.5} className="pr-form-fields-item">
                                                        <TextField
                                                            className={"input_field_width"}
                                                            label="NPN"
                                                            // disabled={disableAllInputs === true ? true : false}
                                                            type="text"
                                                            variant="standard"
                                                            onChange={(e) => {
                                                                CommonContextUpdate("user_national_producer_number", e.target.value);
                                                            }}
                                                            value={agentDetails.user_national_producer_number}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Button
                                                            onClick={handleUpdateAdmin}
                                                            sx={{
                                                                backgroundColor: "rgb(217, 162, 46) !important",
                                                                color: "white !important",
                                                                textTransform: "capitalize !important",
                                                                margin: "20px 0px !important",
                                                                maxWidth: "100px",
                                                                width: "100px"
                                                            }}
                                                        >
                                                            Save
                                                        </Button>
                                                    </Grid>


                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>



        </Fragment>
    );
}

export default AgentProfile;
