import React, { Fragment, useState, useContext, useEffect, memo, useRef } from "react";
import {
  Box,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Select,
  TableHead,
  TableRow,
  Typography,
  Button,
  InputLabel,
  FormControl,
  NativeSelect,
  TextField,
  Dialog,
  DialogTitle,
  Grid,
  ClickAwayListener,
  Snackbar,
  Alert,
} from "@mui/material";
import "./agents.css";
import { Context } from "../../context/userLoaderContext";
import {
  GetUserDetailAPI,
  UpdateUserStatusAPI,
  GetUserStatusAPI,
  UpdateAgentAdminDetailAPI,
} from "../../api/getUserDetailAPI";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/loader";
import Tooltip from '@mui/material/Tooltip';
import MuiPhoneNumber from "material-ui-phone-number";
import GlobalPopUp from "./globalpopup";
import Edit from "../../assets/edit.svg";

function EditAgentPopup(props) {
  const { onClose, selectedValue, open, agentDetails, setAgentDetails } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };
  function changeAgentDetails(key, value) {
    setAgentDetails((prevState) => ({ ...prevState, [key]: value }))
  }
  async function handleSaveAgentDetails() {
    console.log("agentDetails", agentDetails)
  }
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Dialog onBackdropClick={handleClose} onClose={handleClose} open={open}>
        <DialogTitle>Set backup account</DialogTitle>

      </Dialog>
    </ClickAwayListener>
  );
}
let initialAgentData = {
  user_id: "",
  user_first_name: "",
  user_last_name: "",
  user_phone: "",
  user_website: "",
  user_national_producer_number: ""
}
const Agents = () => {
  console.log("component rerendered")
  const [openStatusMenu, setOpenStatusMenu] = useState(false);
  const [tempSnackbarOpen, setTempSnackbarOpen] = useState({
    flagOpen: false,
    message: "",
    severity: "success"
  });

  // const { SetFlagLoader } = useContext(Context);
  const [flagLoader, SetFlagLoader] = useState(false);
  const { state } = useContext(Context);
  const [allAgentList, setAllAgentList] = useState([]);
  const [agents, setAgents] = useState([]);
  const [defaultAgents, setDefaultAgents] = useState([]);
  const [userStatus, setUserStatus] = useState();
  const [flagEditAgent, setFlagEditAgent] = useState();
  const [page, setPage] = useState(1);
  const [flagSearch, setFlagSearch] = useState(false);
  console.log("agents", agents)
  const [agentDetails, setAgentDetails] = useState(initialAgentData)

  console.log("agentDetails----------", agentDetails)
  const navigate = useNavigate();
  useEffect(() => {
    console.log("$$$$$", state.userType === "Admin");
    if (state.userType !== "Admin") {
      navigate("/myga_application_history");
    }
  });

  // useEffect(() => {
  //   if (userStatus == undefined) {
  //     SetFlagLoader(true);
  //   } else {
  //     SetFlagLoader(false);
  //   }
  // }, [userStatus]);

  useEffect(() => {
    SetFlagLoader(true);
    GetUserStatusAPI(
      {},
      (res) => {
        if (res.data.status === true) {
          setUserStatus(res.data.data);
          setTimeout(function () {
          }, 1000);
        }
      },
      (err) => {
        console.log("error while getting user detail", err);
      }
    );
    GetUserDetailAPI(
      {},
      (res) => {
        console.log("hello world", res.data.data);
        if (res.data.status === true) {
          let sortedData = res.data?.data.sort(function (a, b) {
            return (a.first_name) < (b.first_name) ? -1 : 1
          })
          setAllAgentList(sortedData);
          setDefaultAgents(sortedData);
        }
        SetFlagLoader(false);
      },
      (err) => {
        if (err.response.status === 401) {
          window.location.reload();
        }
        SetFlagLoader(false);
      }
    );

  }, []);
  const containerRef = useRef(null);
  useEffect(() => {
    if (allAgentList.length > 0) {
      loadInitialData();
    }
  }, [allAgentList]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [containerRef.current]);

  useEffect(() => {
    if (page > 1) {
      loadMoreData();
    }
  }, [page]);

  const loadInitialData = () => {
    const initialData = allAgentList?.slice(0, 20);
    setAgents(initialData);
  };

  const loadMoreData = () => {
    if (!flagSearch) {
      const newPageData = allAgentList?.slice(page * 20, (page + 1) * 20);
      setAgents(prevData => [...prevData, ...newPageData]);
    }
  };

  const handleScroll = () => {
    const container = containerRef.current;
    if (container.scrollTop + container.clientHeight >= container.scrollHeight - 5) {
      setPage(prevPage => prevPage + 1);
    }
  };
  const tableHeader = ["Edit", "Name", "Email", "Phone", "Registered On", "Status", "NPN"];
  useEffect(() => {
    agents.sort((a, b) => new Date(b.date) - new Date(a.date));
  }, []);
  const changeStatus = (status, userid) => {
    SetFlagLoader(true);
    UpdateUserStatusAPI(
      {
        status_id: status,
        user_id: userid,
      },
      (res) => {
        // debugger;
        SetFlagLoader(false);
        console.log("Printing response of user update", res);
      },
      (err) => {
        SetFlagLoader(false);
        console.log("got error for of user update", err);
      }
    );
  };

  const searchAgent = (value) => {
    if (!value) {
      let agents = allAgentList?.slice(0, 20);
      setAgents(agents)
      setFlagSearch(false)
    } else {
      var filteredData = allAgentList.filter((row) => (row.first_name.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        row.last_name.toLowerCase().indexOf(value.toLowerCase()) > -1))
      setAgents(filteredData)
      setFlagSearch(!!value);
      containerRef.current.removeEventListener('scroll', handleScroll);
    }
  }

  const getTooltipContent = (firstName, lastName) => {
    return `${firstName} ${lastName}`
  }
  function changeAgentDetails(key, value) {
    setAgentDetails((prevState) => ({ ...prevState, [key]: value }))
  }
  async function handleSaveAgentDetails() {
    SetFlagLoader(true)
    await UpdateAgentAdminDetailAPI(agentDetails, (res) => {
      setTempSnackbarOpen((prevState) => ({ ...prevState, flagOpen: true, message: res.data.message.message, severity: "success" }))
      setFlagEditAgent(false);
      setAgents((prevState) =>
        prevState.map((agent) =>
          agent.user_id === agentDetails.user_id
            ? { ...agent, first_name: agentDetails.user_first_name, last_name: agentDetails.user_last_name, phone: agentDetails.user_phone, website: agentDetails.user_website, npn: agentDetails.user_national_producer_number }
            : agent
        )
      );
      setAgentDetails(initialAgentData)
      console.log("res", res)
    }, (err) => {
      console.log("error", err)
      setTempSnackbarOpen((prevState) => ({ ...prevState, flagOpen: true, message: "Something went wrong! Please try again later", severity: "error" }))
    })
    SetFlagLoader(false)
  }
  return (
    <Fragment>
      <Snackbar
        open={tempSnackbarOpen.flagOpen}
        autoHideDuration={3000}
        onClose={() => {
          setTempSnackbarOpen((prevState) => ({ ...prevState, flagOpen: false, message: "" }));
        }}
      >
        <Alert
          onClose={() => {
            setTempSnackbarOpen((prevState) => ({ ...prevState, flagOpen: false, message: "" }));
          }}
          severity={tempSnackbarOpen.severity}
          sx={{ width: "100%" }}
        >
          {tempSnackbarOpen.message}
        </Alert>
      </Snackbar>
      {flagLoader === true && <Loader />}
      {/* {flagEditAgent &&
        <EditAgentPopup open={flagEditAgent}
          handleClose={() => { setFlagEditAgent(false) }}
          agentDetails={agentDetails}
          setAgentDetails={setAgentDetails}
        />
      } */}
      {flagEditAgent &&
        <GlobalPopUp open={flagEditAgent}
          handleClose={() => { setFlagEditAgent(false); setAgentDetails(initialAgentData) }}
          agentDetails={agentDetails}
          flagShowCloseIcon={true}
          setAgentDetails={setAgentDetails}
          title={"Update Agent Details"}
          paperMinWidth={"370px !important"}
        >
          <Grid container direction="column" gap="15px" sx={{ padding: "0px 45px" }}>
            <Grid item>
              <TextField
                className="input_field_width req_fields"
                id="standard-basic"
                label="First name"
                type="text"
                variant="standard"
                onChange={(e) => {
                  changeAgentDetails(
                    "user_first_name",
                    e.target.value
                  );
                }}
                // disabled={disableAllInputs === true ? true : false}
                value={agentDetails.user_first_name}
              />
            </Grid>
            <Grid item>
              <TextField
                className="input_field_width req_fields"
                label="Last name"
                type="text"
                variant="standard"
                onChange={(e) => {
                  changeAgentDetails(
                    "user_last_name",
                    e.target.value
                  );
                }}
                value={agentDetails.user_last_name}
              // disabled={disableAllInputs === true ? true : false}
              />
            </Grid>
            <Grid item>
              <MuiPhoneNumber
                sx={{
                  backgroundColor: "#fff !important",
                  '& .MuiPhoneNumber-flagButton': {
                    pointerEvents: "none"
                  },
                  '& .MuiButtonBase-root': {
                    backgroundColor: "unset !important"
                  },
                }}
                name="phone"
                label="Business telephone number"
                data-cy="user-phone"
                defaultCountry={"us"}
                onlyCountries={["us"]}
                autoFormat={true}
                countryCodeEditable={false}
                value={agentDetails.user_phone}
                className="input_field_width"
                onChange={(value) => {
                  // let temp = { ...producerValidationError }
                  // temp.flagProducerPhoneValid = !validatePhoneNumberLatest(value)
                  // setProducerValidationError(temp)
                  changeAgentDetails("user_phone", value)
                }}
              // error={producerValidationError.flagProducerPhoneValid ? true : false}
              // helperText={producerValidationError.flagProducerPhoneValid ? "Invaild Phone" : ""}
              // disabled={disableAllInputs === true ? true : false}
              />
            </Grid>
            <Grid item>
              <TextField
                className="input_field_width req_fields"
                label="Website"
                type="text"
                variant="standard"
                onChange={(e) => {
                  changeAgentDetails(
                    "user_website",
                    e.target.value
                  );
                }}
                value={agentDetails.user_website}
              // disabled={disableAllInputs === true ? true : false}
              />
            </Grid>
            <Grid item>
              <TextField
                className="input_field_width req_fields"
                // error={producerValidationError?.flagProducerNPNValid ? true : false}
                // helperText={producerValidationError?.flagProducerNPNValid ? "Invalid national producer number" : ""}
                label="National producer number"
                type="number"
                variant="standard"
                onChange={(e) => {
                  // let temp = { ...producerValidationError }
                  // if (e.target.value?.length) {
                  //     temp.flagProducerNPNValid = !/^\d{1,10}$/.test(e.target.value)
                  //     setProducerValidationError(temp)
                  // } else {
                  //     temp.flagProducerNPNValid = false
                  //     setProducerValidationError(temp)
                  // }
                  changeAgentDetails("user_national_producer_number", e.target.value);
                }}
                // onBlur={() => {
                //     let temp = { ...producerValidationError }
                //     if (producerDisclosureForm.producerNationalNumber?.length) {
                //         temp.flagProducerNPNValid = !/^\d{1,10}$/.test(producerDisclosureForm.producerNationalNumber)
                //         setProducerValidationError(temp)
                //     } else {
                //         temp.flagProducerNPNValid = false
                //         setProducerValidationError(temp)
                //     }
                // }}
                value={agentDetails.user_national_producer_number}
              // disabled={disableAllInputs === true ? true : false}
              />
            </Grid>
            <Grid item>
              <Button
                sx={{
                  backgroundColor: "rgb(217, 162, 46) !important",
                  color: "white !important",
                  textTransform: "capitalize !important",
                  margin: "20px 10px !important",
                }}
                onClick={handleSaveAgentDetails}>Submit</Button>
            </Grid>
          </Grid>
        </GlobalPopUp>
      }
      <Box className="agents_page_root">
        <Box className="page_title">
          <Typography variant="span page_title">Agents</Typography>
        </Box>
        <Box className="agentsSearchBarBox" >
          <TextField
            className="agentsSearchBarTextField"
            // id="filled-hidden-label-small"
            // variant="filled"
            // size="small"
            placeholder="Search..."
            onChange={(e) => {
              searchAgent(e.target.value);
            }}
          />
        </Box>
        <Box className="agents_table_root">
          <TableContainer sx={{ maxHeight: 720 }} ref={containerRef}>
            <Table className="agents_table" stickyHeader>
              <TableHead className="agents_table_head">
                <TableRow>
                  {tableHeader.map((v) => {
                    return <TableCell>{v}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {agents.map((row, i) => {
                  return (
                    <TableRow className="agents_table_data">
                      <TableCell>
                        <img
                          alt="edit"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setFlagEditAgent(true); setAgentDetails({
                              user_id: row.user_id,
                              user_first_name: row.first_name,
                              user_last_name: row.last_name,
                              user_phone: row.phone,
                              user_website: row.website,
                              user_national_producer_number: row.npn
                            })
                          }}
                          src={Edit} />
                      </TableCell>
                      <TableCell>
                        <Tooltip title={getTooltipContent(row.first_name, row.last_name)}>
                          <span>
                            {(row.first_name + row.last_name)?.length > 31 ? (row.first_name + row.last_name).substring(0, 31) + "..." : row.first_name + " " + row.last_name}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip title={getTooltipContent(row.first_name, row.last_name)}>
                          <span>
                            {(row.email)?.length > 31 ? (row.email).substring(0, 31) + "..." : row.email}
                          </span>
                        </Tooltip>
                        {/* {row?.email} */}
                      </TableCell>
                      <TableCell>
                        {row?.phone}
                      </TableCell>
                      <TableCell>
                        {moment(row.created_at).format("MM-DD-yyyy")}
                      </TableCell>
                      <TableCell>
                        <Select
                          className="agents_status_select"
                          value={row.status}
                          onChange={(e) => {
                            let userid;
                            userStatus?.filter((v) => {
                              if (v.name === e.target.value) {
                                userid = v.id;
                                row.status = e.target.value;
                              }
                            });
                            changeStatus(userid, row.user_id);
                          }}
                          variant="standard"
                        >
                          {userStatus?.length > 0 && userStatus.map((v) => {
                            return <MenuItem value={v.name}>{v.name}</MenuItem>;
                          })}
                        </Select>
                      </TableCell>
                      <TableCell>
                        {row?.npn}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Fragment>
  );
}

export default Agents;
